body {
  margin: 0;
}

.mat-checkbox-layout,
.mat-step-label {
  white-space: normal !important;
}

.mat-form-field-infix {
  width: auto !important;
}

.mat-form-field-invalid {
  padding-bottom: 25px;
}


@media(max-width: 380px) {
  .mat-expansion-panel-body {
    padding: 0 0 10px 5px !important;
  }

  .mat-chip {
    font-size: 11px !important;
  }

}
