@import "@angular/material/theming";

@font-face {
  font-family: ClientFontRegular;
  src: url(/assets/fonts/Merriweather-Regular.ttf) format("truetype");
}

@font-face {
  font-family: ClientFontBold;
  src: url(/assets/fonts/Merriweather-Bold.ttf) format("truetype");
}

$nbs-typography: mat-typography-config($font-family: ClientFontRegular,
  $display-4: mat-typography-level(112px, 112px, 300, ClientFontBold),
  $display-3: mat-typography-level(56px, 56px, 400, ClientFontBold),
  $display-2: mat-typography-level(45px, 48px, 400, ClientFontBold),
  $display-1: mat-typography-level(34px, 40px, 400, ClientFontBold),
  $headline: mat-typography-level(24px, 32px, 400, ClientFontBold),
  $title: mat-typography-level(20px, 32px, 500, ClientFontBold),
  $subheading-2: mat-typography-level(18px, 28px, 400, ClientFontBold),
  $subheading-1: mat-typography-level(16px, 24px, 400, ClientFontBold),
  $body-2: mat-typography-level(15px, 24px, 500, ClientFontRegular),
  $body-1: mat-typography-level(15px, 20px, 400, ClientFontRegular),
  $caption: mat-typography-level(12px, 20px, 400, ClientFontRegular),
  $button: mat-typography-level(15px, 14px, 500, ClientFontBold),
  $input: mat-typography-level(inherit, 1.125, 400, ClientFontRegular),
);

@include mat-core($nbs-typography);

.mat-expansion-panel-header {
  @include mat-typography-level-to-styles($nbs-typography, body-2);
}

.mat-step-header .mat-step-label.mat-step-label-selected {
  @include mat-typography-level-to-styles($nbs-typography, body-2);
}

.mat-typography .mat-display-1,
.mat-typography h1,
.mat-typography h2,
.mat-typography h3,
.mat-typography h4 {
  margin: 0.83em 0.83em 0.83em 0.83em;
}
#hometitle{display: none;}

#homeOverview{
  max-width: 1200px;
  text-align: center;
}

#homeLogin{
  display: none;
}

$nbs-primary-color: (50: #e3eae6,
  100: #b8c0cc,
  200: hsl(209, 16%, 60%),
  300: #597187,
  400: #364c6e,
  500: #000,
  600: #000,
  700: #000,
  800: #000,
  900: #000,
  A100: #d2b979,
  A200: #d2b979,
  A400: #d2b979,
  A700: #d2b979,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$nbs-accent-color: (50: #e7e9ea,
  100: #c4c8ca,
  200: #9ca3a7,
  300: #747e83,
  400: #576269,
  500: #39464e,
  600: #333f47,
  700: #2c373d,
  800: #242f35,
  900: #172025,
  A100: #d2b979,
  A200: #d2b979,
  A400: #d2b979,
  A700: #d2b979,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$nbs-warn-color: (50: #f8e2e4,
  100: #edb7ba,
  200: #e1878d,
  300: #d4565f,
  400: #cb323c,
  500: #c20e1a,
  600: #bc0c17,
  700: #b40a13,
  800: #ac080f,
  900: #9f0408,
  A100: #ffc9ca,
  A200: #ff9698,
  A400: #ff6366,
  A700: #ff4a4c,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$nbs-app-theme: mat-light-theme((color: (primary: mat-palette($nbs-primary-color),
      accent: mat-palette($nbs-accent-color),
      warn: mat-palette($nbs-warn-color),
    ),
  ));

@include angular-material-theme($nbs-app-theme);

.page-logo {
  background-image   : url(assets/images/nbs-logo.svg);
  background-position: center;
  background-repeat  : no-repeat;
  background-size    : 48px;
  width              : 48px;
  height             : 100%;
  padding-right      : 10px;
}

.fourohfour-logo {
  background-image   : url(assets/images/404.svg);
  background-position: center;
  background-repeat  : no-repeat;
  background-size    : 400px;
  width              : 400px;
  height             : 200px;
}

#nav-logo {
  width              : 120px;
  height             : 100%;
  margin-bottom      : -1rem !important;
  z-index            : 999;
  position            : relative;
}


@media(max-width: 700px) {
  #nav-logo {
    background-size    : 100px;
    width              : 120px;
    height             : 100%;
  }
}

@media(max-width: 300px) {
  #nav-brand {
    display: none;
  }
}

.disclaimer{
  align-self: flex-end;
  padding: 10px 16px 10px 0px;
  color: rgba(255, 255, 255, .5);
  max-width: 1200px;
}
.disclaimer-container{
  width: 100%;
  background-color: #1d2124;
  position: relative;
  z-index: 1002;
}

.disclaimer:hover{
  color: rgba(255, 255, 255, .75);
}

.navbar {
  margin-bottom: 0px !important;
  max-width: 1200px;
  margin-top: 0.75rem !important;
}

.navbar-content-container {
  background-color: #000;
}

#nav-brand{
  background-color: #1d2124 !important;
  margin-right: 16px;
  padding-top: 2rem !important;
  padding-left: 1rem !important;
  padding-bottom: 1rem !important;
  padding-right: 1rem !important;
  box-sizing: border-box;
  margin-bottom: -2rem !important;
}

#nav-brand:hover{
  background-color: #060708 !important;
}

main{
  margin-top: 40px;
  z-index: 1;
}

.warn-text {
  color: mat-color($nbs-warn-color, 900);
}

.mat-toolbar{
  height: auto;
}

//https://github.com/angular/components/issues/4580#issuecomment-386759677
mat-form-field .mat-form-field {
  &-underline {
    position: static;
  }

  &-subscript-wrapper {
    position: static;
  }
}

.snackbar-theme {
  .mat-simple-snackbar-action {
    color: mat-contrast($nbs-accent-color, 500);
  }
  color: mat-contrast($nbs-accent-color, 500);
  background-color: mat-color($nbs-accent-color, 500);
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: mat-color($nbs-accent-color, 'A100');
}